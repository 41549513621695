@import url("material-ui-font.css");
@import url("material-ui-icons.css");

body {
  margin: 0;
  background-color: rgb(0, 0, 0);
  background-image: url("../assets/background.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
